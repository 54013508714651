@import "../../assets/scss/includes-components.scss";

.pw-carousel-experience-container {
  background-color: map-get($background, ice-blue-97);

  .pw-carousel-experience-title {
    @media screen and (min-width: rem(map-get($breakpoints, sm))) {
      text-align: center;
    }
  }

  .pw-carousel-experience-button-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: rem(24);

    .pw-carousel-experience-subtitle {
      margin-bottom: 0;
      @include font-size-line-weight(20, 27, 700);
      @media screen and (min-width: rem(map-get($breakpoints, md))) {
        @include font-size-line-weight(24, 32);
      }
    }

    .pw-carousel-experience-navigation {
      @include navigation-experience-award-slider;
    }
  }

  .swiper {
    border-radius: rem(8);

    .swiper-slide {
      height: auto;
      width: 80%;

      @media screen and (min-width: rem(map-get($breakpoints, md))) {
        width: auto;
      }

      .swiper-content,
      .pw-simple-card-carousel-reward {
        height: 100%;
      }
    }

    .swiper-pagination-horizontal:not(:last-child) {
      .swiper-pagination-bullet {
        margin-right: rem(12);
      }
    }
  }

  .custom-pagination {
    display: flex;
    justify-content: center;
    margin-top: rem(24);

    .swiper-pagination-bullet {
      display: inline-block;
      border-radius: 100%;
      width: rem(12);
      height: rem(12);
      background-color: transparent;
      border: rem(1) solid map-get($foundation, primary);

      &:not(:last-of-type) {
        margin-right: rem(12);
      }

      &.swiper-pagination-bullet-active {
        width: rem(20);
        height: rem(12);
        border-radius: rem(8);
        background: map-get($foundation, primary);
      }
    }
  }
}
