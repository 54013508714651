@import "../../assets/scss/includes-components";

.pw-subscribe-section {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: rem(200);
  overflow: hidden;
  position: relative;
  text-align: center;
  padding: rem(52) 0 rem(36);
  background-color: map-get($foundation, secondary);
  color: map-get($neutral, 10);

  @media screen and (min-width: rem(map-get($breakpoints, md))) {
    padding: rem(56) 0;
    min-height: rem(336);
  }

  .pw-image-airplane,
  .pw-image-left-line-low,
  .pw-image-right-line-top,
  .pw-image-suitcase {
    position: absolute;
  }

  .pw-image-airplane {
    top: 0;
    left: 0;
    padding: rem(4);

    @media screen and (min-width: rem(map-get($breakpoints, md))) {
      left: auto;
      right: rem(-256);
      top: rem(-40);
      padding: 0;
    }
  }

  .pw-image-left-line-low {
    height: rem(200);
    left: rem(-176);
    top: rem(144);

    @media screen and (min-width: calc(#{rem(map-get($breakpoints, xsm))} + #{rem(25)})) {
      top: rem(110);
    }
  }

  .pw-image-right-line-top {
    height: rem(300);
    right: rem(-136);
    top: 32%;
    transform: translateY(-50%);
  }

  .pw-image-suitcase {
    top: rem(10);
    left: rem(-80);
    height: rem(420);
  }

  .wrapper {
    justify-content: center;

    .pw-subscribe-title {
      padding-bottom: rem(24);
      margin: 0;

      @media screen and (min-width: rem(map-get($breakpoints, sm))) {
        padding-bottom: rem(16);
      }
    }
  }
}