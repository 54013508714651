@import "../../assets/scss/includes-components";

.pw-thank-you-container {
  background: url("../../assets/images/background-thank-you-page.webp")
    no-repeat center / cover;
  color: map-get($neutral, 100);
  margin: 0 rem(-16);
  height: 100%;

  @media screen and (min-width: rem(map-get($breakpoints, md))) {
    margin: 0 rem(-40);
  }

  .pw-thank-you-content {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    text-align: center;
    height: 100%;

    .pw-thank-you-logo,
    .pw-thank-you-text,
    .pw-thank-you-title {
      margin: 0;
    }

    .pw-thank-you-logo {
      font-family: "Inter";
      text-transform: uppercase;
      @include font-size-line-weight(20, 24, 700);
      
      @media screen and (min-width: rem(map-get($breakpoints, md))) {
        @include font-size-line-weight(24, 30);
      }

      .logo-yellow {
        color: map-get($foundation, secondary);
      }
    }

    .pw-thank-you-text {
      margin-bottom: rem(12);
      @include font-size-line-weight(20, 24);

      @media screen and (min-width: rem(map-get($breakpoints, md))) {
        margin-bottom: rem(40);
        @include font-size-line-weight(24, 30);
      }
    }

    .pw-thank-you-title {
      margin-bottom: rem(40);
    }
  }

  .pw-thank-you-container-button {
    @media screen and (min-width: rem(map-get($breakpoints, sm))) {
      display: flex;
      justify-content: flex-end;
    }

    .pw-thank-you-button {
     @include responsive-spacing;
    }
  }
}