@import "../../assets/scss/includes-components.scss";

.pw-discover-prize-section {
  background-color: map-get($background, ice-blue-97);
  padding-top: rem(40);
  padding-bottom: rem(24);

  @media screen and (min-width: rem(map-get($breakpoints, md))) {
    padding-bottom: rem(32);
  }

  .pw-carousel-experience-title,
  .pw-carousel-experience-description {
    @media screen and (min-width: rem(map-get($breakpoints, md))) {
      text-align: center;
    }
  }

  .pw-carousel-experience-description {
    margin-bottom: rem(24);
  }

  .pw-carousel-experience-button-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: rem(24);

    .pw-carousel-experience-subtitle {
      margin-bottom: 0;
      @include font-size-line-weight(20, 27, 700);
      @media screen and (min-width: rem(map-get($breakpoints, md))) {
        @include font-size-line-weight(24, 32);
      }
    }
  }
}
