@import "../includes-components";

.pw-container-home-page {
    .pw-play-discover-section {
        padding-top: rem(148);

        @media screen and (min-width: rem(map-get($breakpoints, md))) {
            padding-top: rem(128);
        }
    }
}