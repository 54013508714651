@import "../../assets/scss/includes-components";

.pw-cannot-partecipate-container {
  .pw-simple-content {
    .pw-simple-content-body {
      margin-bottom: rem(60);

      @media screen and (min-width: rem(map-get($breakpoints, md))) {
        margin-bottom: rem(48);
      }

      .paragraph-semi-bold {
        @media screen and (max-width: rem(map-get($breakpoints, md))) {
          line-height: rem(40);
        }
      }

      .pw-simple-content-image {
        @include unsubscribe-image-width-height;
      }

      .paragraph-small {
        @media screen and (min-width: rem(map-get($breakpoints, md))) {
          @include font-size-line-weight(16, 24);
        }
      }
    }

    .paragraph-cannot-partecipate {
      .pw-cannot-partecipate-link {
        color: map-get($foundation, primary);
      }
    }

    .pw-simple-content-action {
      justify-content: center;

      .pw-simple-content-action-button {
        padding: rem(16) rem(64);
      }
    }
  }
}