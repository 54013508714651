@import "../../assets/scss/includes-components";

.pw-play-hero-section {
  min-height: rem(384);
  padding-top: rem(24);
  background: map-get($shadow-colors, shadow-gradient-3);
  color: map-get($neutral, 100);
  position: relative;

  @media screen and (max-width: calc(#{rem(map-get($breakpoints, xsm))} - #{rem(1)})) {
    min-height: rem(400);
  }

  @media screen and (min-width: rem(map-get($breakpoints, md))) {
    padding-bottom: rem(64);
    padding-top: 0;
    min-height: rem(532);
    z-index: 1;
    display: flex;
    align-items: end;
  }

  .pw-container {
    position: static;

    .pw-play-available-descriprtion,
    .paragraph-xs,
    .pw-play-hero-description,
    .pw-play-available-number {
      margin: 0;
    }

    .pw-play-hero-container-desktop,
    .pw-play-hero-container-title {
      @media screen and (min-width: rem(map-get($breakpoints, md))) {
        display: flex;
        flex-direction: column;
        justify-content: end;
      }
    }

    .pw-play-hero-container-title {
      text-align: center;

      @media screen and (min-width: rem(map-get($breakpoints, md))) {
        text-align: left;
      }

      .pw-desktop-play-hero-img {
        width: rem(480);
        height: auto;
        top: 0;
        position: absolute;
        z-index: -1;
      }
    }

    .pw-play-hero-title {
      text-transform: uppercase;

      .logo-yellow {
        color: map-get($foundation, secondary);
      }
    }

    .pw-play-hero-subtitle {
      margin-bottom: rem(24);

      @media screen and (max-width: calc(#{rem(map-get($breakpoints, xsm))} - #{rem(1)})) {
        margin-bottom: rem(8);
      }


      @media screen and (min-width: rem(map-get($breakpoints, md))) {
        margin-bottom: rem(0);
      }
    }

    .pw-play-hero-container-payment {
      text-align: center;

      .pw-play-hero-postepay-cards-list {
        display: flex;
        overflow-x: auto;
        margin: rem(12) 0;
        padding: rem(8);

        @media screen and (max-width: calc(#{rem(map-get($breakpoints, xsm))} - #{rem(1)})) {
          margin: rem(8) 0;
        }

        @media screen and (min-width: rem(map-get($breakpoints, md))) {
          overflow-x: visible;
          padding: rem(0);
        }

        &::-webkit-scrollbar {
          background: transparent;
          height: 0;
        }

        .pw-play-hero-postepay-container {
          display: flex;

          &:first-child {
            margin-left: auto;
          }

          &:last-child {
            margin-right: auto;
          }

          &.pw-play-hero-opacity {
            .pw-play-hero-single-postepay,
            &:not(:first-child)::before {
              background-color: rgba(map-get($background, ice-blue-97), 0.1);
            }

            .pw-play-hero-postepay-img {
              opacity: 0.4;
            }
          }

          &:not(:first-child) {
            &::before {
              content: "";
              display: block;
              width: rem(20);
              height: rem(2);
              background-color: map-get($background, ice-blue-97);
              margin: auto;
            }
          }

          .pw-play-hero-single-postepay {
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 0 rem(8);
            width: rem(48);
            height: rem(48);
            background-color: map-get($background, ice-blue-97);
            border-radius: 100%;

            @media screen and (max-width: calc(#{rem(map-get($breakpoints, xsm))} - #{rem(1)})) {
              width: rem(40);
              height: rem(40);
            }

            @media screen and (min-width: rem(map-get($breakpoints, lg))) {
              width: rem(72);
              height: rem(72);
            }

            .pw-play-hero-postepay-img {
              width: auto;
              height: rem(20);

              @media screen and (max-width: calc(#{rem(map-get($breakpoints, xsm))} - #{rem(1)})) {
                height: rem(16);
              }

              @media screen and (min-width: rem(map-get($breakpoints, lg))) {
                height: rem(28);
              }
            }
          }
        }
      }

      .pw-play-hero-description {
        @media screen and (min-width: rem(map-get($breakpoints, md))) {
          @include font-size-line-weight(16, 24);
        }
      }

      .paragraph-xs {
        @media screen and (min-width: rem(map-get($breakpoints, md))) {
          font-size: rem(14);
        }
      }

      .pw-play-hero-paragraph-bottom {
        @media screen and (min-width: rem(map-get($breakpoints, md))) {
          @include font-size-line-weight(14, 21);
        }
      }
    }

    .pw-play-available {
      position: absolute;
      background-color: map-get($neutral, 100);
      border: rem(4) solid map-get($border, blue10);
      border-radius: rem(8);
      color: map-get($foundation, primary);
      right: 0;
      left: 0;
      margin: 0 rem(12);
      top: calc(100% - rem(52));
      z-index: 1;

      @media screen and (min-width: rem(map-get($breakpoints, md))) {
        position: initial;
        margin: rem(24) 0 0;
      }

      .pw-play-available-container-content {
        padding: rem(10) rem(16) rem(16);

        @media screen and (min-width: rem(map-get($breakpoints, md))) {
          display: flex;
          align-items: center;
          justify-content: space-between;
          gap: rem(8);
          padding: rem(24);
        }

        .pw-play-available-content {
          padding-bottom: rem(16);
          width: rem(142);

          @media screen and (min-width: rem(map-get($breakpoints, md))) {
            display: flex;
            align-items: center;
            padding-bottom: 0;
            width: auto;
          }

          .pw-play-available-number {
            @include font-size-line-weight(48, 48, 700);

            @media screen and (min-width: rem(map-get($breakpoints, md))) {
              margin-right: rem(16);
              @include font-size-line-weight(62, 74);
            }
          }
        }

        .pw-play-available-button {
          text-transform: uppercase;
          padding: rem(10) rem(24);

          @media screen and (min-width: rem(map-get($breakpoints, md))) {
            padding: rem(16) rem(48);
          }

          .pw-icon {
            @include font-size-line-weight(16, 16);

            @media screen and (min-width: rem(map-get($breakpoints, md))) {
              @include font-size-line-weight(16, 16);
            }
          }
        }
      }

      .pw-play-available-img {
        position: absolute;
        width: auto;
        top: -50%;
        right: 0;
        height: rem(208);
      }
    }

    .pw-br-play-hero-section {
      @media screen and (min-width: rem(map-get($breakpoints, md))) {
        display: none;
      }
    }

    .pw-br-play-hero-section-two {
      @media screen and (max-width: calc(#{rem(map-get($breakpoints, md))} - #{rem(1)})) {
        display: none;
      }
    }
  }
}
